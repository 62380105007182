function createLineChart(canvasID, chartData, labelName) {
    const labels = Object.keys(chartData)
    const data = Object.values(chartData)
    const ctx = document.getElementById(canvasID)
    if (ctx && ctx.style.length == 0) {
        const myLineChart = new Chart(ctx, {
            type: 'line',
            data: {
                labels: labels,
                datasets: [
                    {
                        label: labelName,
                        data: data,
                        borderColor: "rgba(0,0,255,1)",
                        backgroundColor: "rgba(0,0,0,0)",
                        tension: 0.4
                    }
                ],
            },
            options: {
                responsive: true,
                scales: {
                    y: {
                        suggestedMin: 0,
                        suggestedMax: 10
                    },
                    x: {
                        stacked: true
                    },
                },
            }
        });
        document.addEventListener('turbolinks:before-visit', function (event) {
            myLineChart.destroy();
        });
    }
}

window.createLineChart = createLineChart


