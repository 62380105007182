import Noty from "noty"

window.Noty = Noty;

document.addEventListener('turbolinks:load', function () {
    window.showNotice = function (type, text, callBackFunc = function () {
    }) {
        console.log(callBackFunc)
        const noty = new Noty({
            type: type,
            text: text,
            theme: 'sunset',
            timeout: text.length * 180,
            closeWith: ['click', 'button'],
            callbacks: {
                afterClose: callBackFunc,
            }
        }).show();

    }
});